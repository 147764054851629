<template>
  <div class="app">
    <div id="threeContainer" ref="threeContainer"></div>
    <div class="info">
      <span class="ticker" @click="playKachow">$KACHOW</span>
      <audio id="kachow">
        <source src="@/assets/kachow.mp3">
      </audio>
      <span class="ca">CA: loading...</span>
    </div>
    <div class="socials">
      <a href="https://x.com/kachow_on_sol" target="_blank">
        <span class="socials_link" data-text="TWITTER">TWITTER</span>
      </a>
      <a href="https://t.me/kachow_on_sol" target="_blank">
        <span class="socials_link" data-text="TELEGRAM">TELEGRAM</span>
      </a>
      <a>
      <span class="socials_link soon" data-text="DEXSCREENER">
        DEXSCREENER
      </span>
      </a>
    </div>
    <div class="modal" v-if="isModalOpen">
      <div class="modal_content">
        <span class="modal_content_title">Play music?</span>
        <div class="modal_content_btns">
          <span class="modal_content_btns_btn" @click="playMusic">Yes</span>
          <span class="modal_content_btns_btn" @click="playMusic">Hell yeah!</span>
        </div>
      </div>
    </div>
    <audio id="music" loop>
      <source src="@/assets/music.mp3">
    </audio>
    <div class="sound">
      <svg v-if="musicIsOn" @click="playMusic" width="64px" height="64px" viewBox="0 0 24 24" id="sound-max" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" class="icon flat-line">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path id="secondary" d="M11,5V19L7,15H4a1,1,0,0,1-1-1V10A1,1,0,0,1,4,9H7Z" style="fill: #ffab00; stroke-width: 2;"></path>
          <path id="primary" d="M18.36,5.64a9,9,0,0,1,0,12.72" style="fill: none; stroke: #000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
          <path id="primary-2" data-name="primary" d="M15.54,8.46a5,5,0,0,1,0,7.08" style="fill: none; stroke: #000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
          <path id="primary-3" data-name="primary" d="M11,5V19L7,15H4a1,1,0,0,1-1-1V10A1,1,0,0,1,4,9H7Z" style="fill: none; stroke: #000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
        </g>
      </svg>
      <svg v-else @click="playMusic" width="64px" height="64px" viewBox="0 0 24 24" id="sound-mute-alt" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" class="icon flat-line">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path id="secondary" d="M11,5V19L7,15H4a1,1,0,0,1-1-1V10A1,1,0,0,1,4,9H7Z" style="fill: #ffab00; stroke-width: 2;"></path>
          <path id="primary" d="M16,14.5l5-5m-5,0,5,5M7,9H4a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H7l4,4V5Z" style="fill: none; stroke: #000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path>
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const isModalOpen = ref(true)
const musicIsOn = ref(false)
const threeContainer = ref(null);

function playKachow() {
  const audioEl: HTMLAudioElement | null = document.getElementById('kachow')

  if (audioEl) {
    audioEl.play()
  }
}
function playMusic() {
  isModalOpen.value = false
  musicIsOn.value = true
  const audioEl: HTMLAudioElement | null = document.getElementById('music')

  if (audioEl && audioEl.paused) {
    audioEl.play().catch(function(error) {
      console.log('Auto-play was prevented:', error)
    })
  } else if (audioEl && audioEl.played) {
    musicIsOn.value = false
    audioEl.pause()
  }
}

onMounted(() => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, 500 / window.innerHeight, 0.1, 1000);

  const renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.setPixelRatio(window.devicePixelRatio);

  // Функция для установки размеров рендерера и камеры
  const setSize = () => {
    const container = threeContainer.value;
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  };

  // Установка начальных размеров
  setSize();
  threeContainer.value.appendChild(renderer.domElement);

  // Настройка освещения
  const ambientLight = new THREE.AmbientLight(0xffffff); // Равномерное освещение
  scene.add(ambientLight);

  const fixedLight = new THREE.DirectionalLight(0xffffff, 2); // Направленный свет
  fixedLight.position.set(5, 10, 7).normalize();
  scene.add(fixedLight);

  let model = null; // Объявляем переменную для хранения модели

  // Загрузка модели GLTF
  const loader = new GLTFLoader();
  loader.load(
      '/kachow/scene.gltf',
      (gltf) => {
        console.log('Model loaded');
        model = gltf.scene;
        model.scale.set(70, 70, 70);

        // Вычисление центра модели и корректировка позиции
        const box = new THREE.Box3().setFromObject(model);
        const center = new THREE.Vector3();
        box.getCenter(center);
        model.position.sub(center); // Перемещаем модель так, чтобы её центр оказался в начале координат

        // Добавляем модель в сцену
        scene.add(model);

        // Если модель имеет анимации, запустим их
        if (gltf.animations && gltf.animations.length) {
          const mixer = new THREE.AnimationMixer(model);
          gltf.animations.forEach((clip) => mixer.clipAction(clip).play());

          // Обновление анимаций в рендер цикле
          const clock = new THREE.Clock();
          function animate() {
            requestAnimationFrame(animate);
            mixer.update(clock.getDelta());
            controls.update(); // Обновляем управление
            renderer.render(scene, camera);
          }
          animate();
        }
      },
      undefined,
      (error) => {
        console.error('Error loading model:', error);
      }
  );

  // Настройка камеры
  camera.position.set(0, 1, 5);
  camera.lookAt(0, 1, 0);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true; // Включает плавное замедление при вращении
  controls.dampingFactor = 0.25; // Фактор замедления
  controls.enableZoom = true; // Включает зум
  controls.enablePan = false; // Отключает панорамирование

  // Основной цикл анимации
  const animate = () => {
    requestAnimationFrame(animate);
    if (model) {
      model.rotation.y += 0.003; // Плавное вращение модели вокруг оси Y
    }
    controls.update(); // Обновляем управление
    renderer.render(scene, camera);
  };

  animate();

  // Обработка изменения размера окна
  window.addEventListener('resize', setSize);
});
</script>

<style lang="sass">
*
  margin: 0
  padding: 0
  box-sizing: border-box
.app
  font-family: "Rubik Mono One", monospace
  font-weight: 400
  font-style: normal
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  min-height: 100vh
  width: 100vw
  background-image: url("@/assets/bg.webp")
  background-size: cover
#threeContainer
  width: 700px
  height: 500px
.info
  position: absolute
  top: 100px
  color: white
  font-size: 20pt
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 20px
.socials
  position: absolute
  bottom: 100px
  font-size: 20pt
  display: flex
  align-items: flex-start
  gap: 40px
  &_link
    transition: all 0.3s ease
    position: relative
    display: inline-block
    overflow: hidden
    color: white
    cursor: pointer
    &::before
      content: attr(data-text)
      position: absolute
      overflow: hidden
      top: 0
      left: 0
      width: 0
      height: 100%
      color: #d73b3b
      white-space: nowrap
      transition: width 0.2s ease-in
      -webkit-text-stroke-width: 0.8px
      -webkit-text-stroke-color: #d73b3b
    &:hover::before
      width: 100%
    &.soon
      cursor: wait
      overflow: visible
      position: relative
      &::after
        color: white
        position: absolute
        top: -10px
        left: 0
        font-size: 9pt
        content: 'Comming soon'
.ticker
  font-size: 50pt
  color: #ffab00
  -webkit-text-stroke-width: 3px
  -webkit-text-stroke-color: white
  cursor: pointer
  transition: all 0.2s ease
  &:hover
    scale: 1.1
a
  text-decoration: none
  color: inherit
.modal
  width: 100%
  height: 100%
  position: absolute
  z-index: 100
  background-color: rgba(0, 0, 0, 0.3)
  display: flex
  align-items: center
  justify-content: center
  &_content
    width: 350px
    height: 120px
    background-color: white
    border-radius: 8px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    padding: 20px 50px
    &_title
      font-size: 20pt
      color: #d73b3b
    &_btns
      width: 100%
      display: flex
      justify-content: space-between
      &_btn
        transition: all 0.2s ease
        cursor: pointer
        &:hover
          color: #ffab00
.sound
  position: absolute
  top: 20px
  right: 40px
  cursor: pointer
@media (max-width: 768px)
  #threeContainer
    width: 100%
    height: 80%
  .ca
    font-size: 8pt
    color: transparent
    position: relative
    &::after
      content: '...'
      color: white
      position: absolute
      top: 0
      left: 50%
      transform: translateX(-50%)
  .socials
    flex-direction: column
    font-size: 18pt
</style>